import {
  faArrowLeft,
  faBook,
  faCalendar,
  faPlay,
  faSquarePlus,
  faTicket,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import useToken from "./useToken";

import CustomSelect from "./CustomSelect";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const WorkshopComponent = ({ workshopData, setLoading, navigate, token }) => {
  const handleAccessWorkshop = () => {
    if (workshopData.status === "not_started") {
      navigate("/intro/" + workshopData._id);
    } else {
      navigate("/game/" + workshopData._id + "/status");
    }
  };

  const [isVisible, setIsVisible] = useState(true); // État pour contrôler la visibilité du composant

  const handleDeleteWorkshop = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/archive_game`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({ game_id: workshopData._id }),
      });

      if (response.ok) {
        // Masquer le composant si la requête réussit
        setIsVisible(false);
        console.log("Workshop archivé avec succès.");
      } else {
        console.error("Erreur lors de l'archivage de l'atelier :", response.statusText);
      }
    } catch (error) {
      console.error("Erreur lors de la requête :", error);
    }
  };


  const workshopTypes = {
    grand_public: "Grand public",
    entreprise: "Entreprise",
    association: "Association",
    prive: "Privé",
    collectivite: "Collectivité",
    enseignement_superieur: "Enseignement supérieur",
    test: "Test",
  };


  if (!isVisible) {
    return null; // Retourner null pour ne pas afficher le composant
  }



  return (
    <Fragment>
      <div className="workshop-container">
        <Fragment>
          <div className="workshop-name">{workshopData.workshop_name}</div>
          <div className="labels-container">
            <div
              className="workshop-status"
              style={{
                backgroundColor:
                  workshopData.status === "started"
                    ? "green"
                    : workshopData.status === "finished"
                    ? "red"
                    : workshopData.status === "tickets_created"
                    ? "var(--hydros-blue)"
                    : "var(--hydros-orange)",
              }}
            >
              {" "}
              {workshopData.status === "started"
                ? "Commencé"
                : workshopData.status === "finished"
                ? "Fini"
                : workshopData.status === "tickets_created"
                ? "Billeterie créée"
                : "En attente"}
            </div>
            {workshopData.type && (
              <div
                className="workshop-type"
                style={{
                  backgroundColor:
                    workshopData.type === "grand_public"
                      ? "var(--hydros-sand)"
                      : workshopData.type === "entreprise"
                      ? "var(--hydros-blue)"
                      : workshopData.type === "collectivite"
                      ? "var(--hydros-orange)"
                      : workshopData.type === "enseignement_superieur"
                      ? "var(--hydros-mid-blue)"
                      : "green",
                  color:
                    workshopData.type === "grand_public"
                      ? "var(--hydros-blue"
                      : "white",
                }}
              >
                {workshopTypes[workshopData.type]}
              </div>
            )}

            {workshopData.ticketing_url && (
              <a
                href={workshopData.ticketing_url}
                target="_blank"
                rel="noreferrer"
              >
                <Tooltip id="ticketing-tooltip" />
                <div
                  className="ticketing-link"
                  data-tooltip-id="ticketing-tooltip"
                  data-tooltip-content="Lien vers la billeterie de cet atelier"
                >
                  <FontAwesomeIcon
                    className="ticketing-icon"
                    icon={faTicket}
                  />
                </div>
              </a>
            )}
          </div>

          <div className="workshop-bottom-section">
            {workshopData.actors && (
              <div className="workshop-players">
                <FontAwesomeIcon icon={faUser} style={{ marginRight: 7 }} />
                {workshopData.actors.length} joueurs
              </div>
            )}

            <div className="workshop-date">
              <FontAwesomeIcon icon={faCalendar} style={{ marginRight: 7 }} />
              Créé le{" "}
              {new Date(workshopData.created_date).toLocaleDateString(
                "fr-FR"
              )}
            </div>
          </div>
        </Fragment>
        <div className="workshop-button-container">
          <button
            className="blue-button no-margin"
            onClick={handleAccessWorkshop}
            style={{ width: '70%' }}
          >
            Accéder
            <FontAwesomeIcon style={{ marginLeft: 7 }} icon={faPlay} />
          </button>

          <button
            className="blue-button no-margin"
            onClick={handleDeleteWorkshop}
            style={{ width: '20%' }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>

      </div>
    </Fragment>
  );
};

function SelectComponent({ options, onSelect }) {
  const [selectedOption, setSelectedOption] = useState("");

  // Fonction pour gérer la sélection d'une option
  const handleOptionSelect = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Appeler la fonction de rappel onSelect avec l'option sélectionnée
    onSelect(selectedValue);
  };

  return (
    <div>
      <select
        className="profile-select-workshop"
        value={selectedOption}
        onChange={handleOptionSelect}
      >
        <option value="all">Tous</option>
        {options.map((option) => (
          <option value={option.key}>{option.value}</option>
        ))}
      </select>
    </div>
  );
}

function Profile(props) {
  const navigate = useNavigate();
  const status_options = [
    { key: "finished", value: "Fini" },
    { key: "started", value: "Commencé" },
    { key: "not_started", value: "Programmé" },
  ];

  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [workshops, setWorkshops] = useState([]);

  const handleWorkshopNew = () => {
    navigate("/config");
  };

  const handleWorkshopFilter = (status) => {
    console.log(status);
    if (status == "all") {
      setWorkshops(profileData.list_games);
    } else {
      setWorkshops(
        profileData.list_games.filter((workshop) => workshop.status === status)
      );
    }
  };

  const fetchProfileInfos = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + props.token,
        },
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 422) {
          navigate("/login"); // Use navigate to redirect to "/login"
        } else {
          // Handle other error responses
          console.error("Error fetching profile data:", response.statusText);
        }
        return;
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const fetchProfileListGames = async () => {
    return await (
      await fetch(`${process.env.REACT_APP_API_URL}/profile_list_games`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + props.token,
        },
      })
    ).json();
  };

  useEffect(() => {
    setLoading(true);
    const initProfile = async () => {
      const res = await fetchProfileInfos();
      if (res && res.msg && res.msg === "Token has expired") {
        navigate("/login");
      }
      const listGame = await fetchProfileListGames();
      if (res) {
        res.access_token && props.setToken(res.access_token);
        console.log(res.access_token);
        setProfileData({
          firstname: res.firstname,
          certification: res.certification_level,
          is_admin: res.is_admin,
          number_done_workshop: listGame.filter(
            (workshop) => workshop.status === "finished"
          ).length,
          number_waiting_workshop: listGame.filter(
            (workshop) => workshop.status === "not_started"
          ).length,
          number_people_workshop: listGame
            .filter((workshop) => workshop.status === "finished")
            .reduce((accumulator, workshop) => {
              return accumulator + workshop.number_player;
            }, 0),
          list_games: listGame,
        });
        setWorkshops(listGame);
        setLoading(false);
      }
    };
    initProfile();
  }, []);

  return (
    <Fragment>
      {loading ? (
        <div className="loader-container">
          <img src="./loader.gif" className="loader" />
        </div>
      ) : (
        <div className="profile-page">
          <div className="profile-top-container">
            <h1 className="color-blue">Bonjour {profileData.firstname} !</h1>
            <a
              href="https://compte-gouttes.notion.site/Espace-animateurs-e39d0790584c436f96fe86ad8bfef3d8?v=7425610aa5fb4f9ead7828f78dd850b9"
              target="_blank"
              rel="noreferrer"
            >
              <button className="orange-button new-workshop-button">
                <FontAwesomeIcon icon={faBook} style={{ marginRight: 7 }} />
                Ressources animateur
              </button>
            </a>
          </div>
          <div className="workshops-list-container">
          {profileData.is_admin ? 
            (<div className="profile-config-container">
              <div className="profile-top-container">
                <h2 className="profile-workshop-title color-blue">
                  Gérer les configurations
                </h2>
              </div>
              <div className="stats-wrapper">
                <button className="blue-button" onClick={()=>{navigate('/game_config_list')}}>Gérer les parties</button>
                <button className="blue-button" onClick={()=>{navigate('/decision_list')}}>Gérer les décisions</button>
                <button className="blue-button" onClick={()=>{navigate('/consequence_list')}}>Gérer les conséquences</button>
              </div>
            </div>):(<div></div>)}

            <div className="profile-top-container">
              <h2 className="profile-workshop-title color-blue">
                Mes statistiques
              </h2>
            </div>


            <div className="stats-wrapper">
              <div className="stat-container">
                <div className="stat-value">
                  {profileData.number_done_workshop}
                </div>
                <div className="stat-header">Ateliers animés</div>
                <div>
                  <img className="stat-logo" src="/atelier.png" />
                </div>
              </div>

              <div className="stat-container">
                <div className="stat-value">
                  {profileData.number_people_workshop}
                </div>
                <div className="stat-header">Personnes formées</div>
                <div>
                  <img className="stat-logo" src="/reseau.png" />
                </div>
              </div>

              <div className="stat-container">
                <div className="stat-value">
                  {profileData.number_waiting_workshop}
                </div>
                <div className="stat-header">Ateliers en attente</div>
                <div>
                  <img className="stat-logo" src="/time-and-calendar.png" />
                </div>
              </div>
            </div>

            <div className="profile-top-container">
              <h2 className="profile-workshop-title color-blue">
                Mes ateliers
              </h2>
            </div>
            <div className="profile-toolbar">
              <SelectComponent
                options={status_options}
                onSelect={handleWorkshopFilter}
              />
              <button
                className="orange-button new-workshop-button"
                onClick={handleWorkshopNew}
              >
                <FontAwesomeIcon
                  icon={faSquarePlus}
                  style={{ marginRight: 7 }}
                />
                Créer un nouvel atelier
              </button>
            </div>

            <div className="workshops-list">
              {workshops.map((workshop) => (
                <WorkshopComponent
                  key={workshop._id}
                  workshopData={workshop}
                  navigate={navigate}
                  setLoading={setLoading}
                  token={props.token}
                ></WorkshopComponent>
              ))}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Profile;
