import React from "react";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Redirige vers la page d'accueil
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Accès refusé</h1>
      <p style={styles.message}>
        Vous n'avez pas les droits nécessaires pour accéder à cette page. 
        Si vous pensez qu'il s'agit d'une erreur, contactez l'administrateur.
      </p>
      <button onClick={handleGoHome} style={styles.button}>
        Retour à l'accueil
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "#f8f9fa", // Couleur de fond légère
  },
  title: {
    fontSize: "2.5rem",
    color: "#ff4b5c", // Couleur de texte pour attirer l'attention
    marginBottom: "20px",
  },
  message: {
    fontSize: "1.2rem",
    color: "#333",
    marginBottom: "30px",
    maxWidth: "600px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#007bff", // Couleur de bouton
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default Unauthorized;
