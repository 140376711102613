import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import { FaTrash } from 'react-icons/fa'; // Icône pour la suppression
import { v4 as uuidv4 } from "uuid";


import DecisionConsequences from './GameFormComponents/DecisionConsequenceComponent';
import DecisionListDropdown from './GameFormComponents/DecisionListDropdown';
import OtherConsequences from './GameFormComponents/OtherConsequencesSelector';
import ActorCustomization from './GameFormComponents/ActorCustomization';
import CustomIntroduction from './GameFormComponents/IntroductionCustomization';
import useToken from './useToken';
import "../form.css";
import { faL } from '@fortawesome/free-solid-svg-icons';



const GameForm = ({edit, GameConfigId}) => {
  const [formData, setFormData] = useState({
    _id: uuidv4(),
    name: '',
    decisions_list: [],
    other_consequences_list:[],
    language: 'fr',
    custom_intro: false,
    intro_img_list: [],
    bassin_intro: "rhone",
    custom_actor: false,
    custom_actor_list: [],
  });

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Empêche la soumission du formulaire
    }
  };
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();


  // security check
  const { token } = useToken();

  const fetchProfileInfos = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (!response.ok) {
        if (response.status === 401 || response.status === 422) {
          navigate("/login"); // Redirection si non autorisé
        } else {
          console.error("Erreur lors du chargement des données de profil :", response.statusText);
        }
        return;
      }
      const profileData = await response.json();
      // Vérification des droits d'accès
      if (!profileData.is_admin) {
        navigate("/401"); // Redirection si l'utilisateur n'est pas admin
      }
    } catch (error) {
      console.error("Erreur lors du chargement des données de profil :", error);
    }
  };

  const fetchGameConfig = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/game_config_info/${GameConfigId}`);
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des conséquences");
      }
      const data = await response.json();
      setFormData(data); // Charger les conséquences dans l'état
    } catch (error) {
      console.error("Erreur lors du chargement des conséquences:", error);
    }
    setLoading(false)
  };

  const fetchConsequences = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get_all_consequences`);
      const data = await response.json();
      setConsequences(data);
    } catch (error) {
      console.error("Erreur lors du chargement des conséquences :", error);
    } finally {
      setLoadingConsequences(false);
    }
  };
  const fetchDecisions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/load_all_decisions_config`);
      const data = await response.json();
      setAvailableDecisions(data); // Assume que data est un tableau d'objets décision
    } catch (error) {
      console.error("Erreur lors du chargement des décisions :", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchProfileInfos();
    fetchConsequences();
    fetchDecisions();
    if (edit) {
      fetchGameConfig();
    }
    setLoading(false);
  }, []); 



  const [availableDecisions, setAvailableDecisions] = useState([]);
  const [showDecisionDropdown, setShowDecisionDropdown] = useState(false);
  const [selectedDecisionId, setSelectedDecisionId] = useState('');

  const [consequences, setConsequences] = useState([]);
  const [loadingConsequences, setLoadingConsequences] = useState(true);

  // État pour le tag sélectionné
  const [selectedTag, setSelectedTag] = useState("");

  // État pour stocker tous les tags disponibles
  const [availableTags, setAvailableTags] = useState(["standard"]);


    
  const handleBackToConfigs = () => {
    navigate("/game_config_list"); // Utiliser `navigate` pour rediriger
  };

    const handleUpdateDecisionField = (decisionId, field, value) => {
    const updatedDecisions = formData.decisions_list.map((decision) => {
        if (decision.decision_id === decisionId) {
        return {
            ...decision,
            [field]: value,
            ...(field === 'custom_consequence' && !value
            ? { custom_consequences_taken: [], custom_consequences_not_taken: [] }
            : {}),
        };
        }
        return decision;
    });
    
    setFormData((prevData) => ({
        ...prevData,
        decisions_list: updatedDecisions,
    }));
    };

  const handleUpdateDecisionConsequences = (decisionId, action, consequenceId) => {
    const updatedDecisions = formData.decisions_list.map((decision) => {
      if (decision.decision_id === decisionId) {
        if (action === 'consequences_taken') {
          return {
            ...decision,
            custom_consequences_taken: [...decision.custom_consequences_taken, consequenceId],
          };
        } else if (action === 'consequences_not_taken') {
          return {
            ...decision,
            custom_consequences_not_taken: [...decision.custom_consequences_not_taken, consequenceId],
          };
        } else if (action === 'remove_taken') {
          return {
            ...decision,
            custom_consequences_taken: decision.custom_consequences_taken.filter((id) => id !== consequenceId),
          };
        } else if (action === 'remove_not_taken') {
          return {
            ...decision,
            custom_consequences_not_taken: decision.custom_consequences_not_taken.filter((id) => id !== consequenceId),
          };
        }
      }
      return decision;
    });

    setFormData((prevData) => ({
      ...prevData,
      decisions_list: updatedDecisions,
    }));
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDecisionChange = (index, field, value) => {
    const updatedDecisions = [...formData.decisions_list];
    updatedDecisions[index][field] = value;
    setFormData((prevData) => ({
      ...prevData,
      decisions_list: updatedDecisions,
    }));
  };

  const handleAddDecisionsByTag = () => {
    // Supposons que tu as une fonction pour obtenir toutes les décisions par tag
    const decisionsToAdd = getDecisionsByTag(selectedTag); // Fonction fictive à implémenter
  
    // Formater chaque décision dans le format requis
    const formattedDecisions = decisionsToAdd.map((decision) => ({
      decision_id: decision._id,
      title: decision.title,
      forced_rounds: [],
      from_round_number: -1,
      decision_duplicate: true,
      custom_consequence: false,
      custom_consequences_taken: [],
      custom_consequences_not_taken: [],
    }));
  
    // Filtrer les décisions déjà présentes dans formData.decisions_list
    const newDecisions = formattedDecisions.filter(
      (newDecision) => 
        !formData.decisions_list.some(
          (existingDecision) => existingDecision.decision_id === newDecision.decision_id
        )
    );
  
    // Mettre à jour la liste des décisions dans le formulaire uniquement avec les nouvelles décisions
    const updatedDecisions = [...formData.decisions_list, ...newDecisions];
    setFormData({ ...formData, decisions_list: updatedDecisions });
  
    // Réinitialiser le tag sélectionné
    setSelectedTag("");
  };
  
  
  
  const handleRemoveDecision = (index) => {
    const updatedDecisions = formData.decisions_list.filter((_, i) => i !== index);
    setFormData({ ...formData, decisions_list: updatedDecisions });
  };


  const getDecisionsByTag = (tag) => {

    return availableDecisions.filter(decision => decision.tags.includes(tag));
  };
  

  const addDecision = (decision) => {
    setFormData((prevData) => ({
      ...prevData,
      decisions_list: [
        ...prevData.decisions_list,
        {
          decision_id: decision._id,
          title: decision.title,
          forced_rounds: [],
          from_round_number: -1,
          decision_duplicate: true,
          custom_consequence: false,
          custom_consequences_taken: [],
          custom_consequences_not_taken: [],
        },
      ],
    }));
    setShowDecisionDropdown(false); // Fermer le menu déroulant après ajout
  };

  const removeDecision = (index) => {
    const updatedDecisions = [...formData.decisions_list];
    updatedDecisions.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      decisions_list: updatedDecisions,
    }));
  };

  const toggleDecisionDetails = (index) => {
    const updatedDecisions = [...formData.decisions_list];
    updatedDecisions[index].isExpanded = !updatedDecisions[index].isExpanded; // Toggle pour déplier
    setFormData((prevData) => ({
      ...prevData,
      decisions_list: updatedDecisions,
    }));
  };

  const handleCustomActorChange = (index, value) => {
    const updatedActors = [...formData.custom_actor_list];
    updatedActors[index].dreal.name = value;
    setFormData((prevData) => ({
      ...prevData,
      custom_actor_list: updatedActors,
    }));
  };

  const addActor = () => {
    setFormData((prevData) => ({
      ...prevData,
      custom_actor_list: [
        ...prevData.custom_actor_list,
        { dreal: { name: '' } },
      ],
    }));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
    
  //   if (edit) {
  //         // Envoyer les données à l'API
  //   fetch(`${process.env.REACT_APP_API_URL}/replace_game_config/${GameConfigId}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(formData),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => console.log("Game Config added:", data))
  //     .catch((error) => console.error("Error:", error));

  //   } else {
  //         // Envoyer les données à l'API
  //     fetch(`${process.env.REACT_APP_API_URL}/add_game_config`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => console.log("Game Config added:", data))
  //       .catch((error) => console.error("Error:", error));
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Bloquer le bouton Submit pendant la requête
    setSubmitDisabled(true);
  
    const url = edit
      ? `${process.env.REACT_APP_API_URL}/replace_game_config/${GameConfigId}`
      : `${process.env.REACT_APP_API_URL}/add_game_config`;
  
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // En cas de succès, afficher le message et ne pas réactiver le bouton submit
          setMessage("La configuration du jeu a été mise à jour avec succès.");
        } else {
          // En cas d'échec, afficher le message d'erreur et réactiver le bouton submit
          setMessage(`${data.message}`);
          setSubmitDisabled(false); // Réactiver le bouton en cas d'échec
        }
      })
      .catch((error) => {
        // Afficher un message d'erreur en cas de problème de requête
        setMessage(`Erreur de requête: ${error.message}`);
        setSubmitDisabled(false); // Réactiver le bouton en cas d'erreur
      });
  };
  




  return (
    <Fragment>
    {loading ? (
      <div className="loader-container">
        <img src="/loader.gif" className="loader" />
      </div>
    ) : (
    <div className="measure-form-container">
        <h1 className="form-title">Ajouter un type d'Atelier</h1>
        <form onSubmit={handleSubmit}>
        {/* Champ pour l'ID de l'atelier */}

        <div className="form-section">
            <div className="form-section-title">Informations générales</div>
                {!edit ? (<div className="form-group">
                  <label>ID :</label>
                  <input
                  type="text"
                  name="_id"
                  value={formData._id}
                  onChange={handleInputChange}
                  className="input"
                  placeholder="ID de l'atelier"
                  onKeyDown={handleKeyDown}
                  required
                  />
                </div>):(<div><div>ID : {formData._id}</div><br></br></div>)}

                {/* Champ pour le nom de l'atelier */}
                <div className="form-group">
                    <label>Nom :</label>
                    <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="input"
                    placeholder="Nom de l'atelier"
                    onKeyDown={handleKeyDown}
                    />
                </div>

                <div className="form-group">
                    <label>Langue :</label>
                    <select
                    name="language"
                    value={formData.language}
                    onChange={handleInputChange}
                    className="input"
                    >
                    <option value="fr">Français</option>
                    <option value="en">Anglais</option>
                    </select>
                </div>
            </div>

        {/* Section des décisions */}
        <div className="form-section">
            <div className="form-section-title">Paramètrage décisions</div>

            <label htmlFor="tagSelect">Sélectionner un tag :</label>
            <select
            id="tagSelect"
            value={selectedTag}
            onChange={(e) => setSelectedTag(e.target.value)}
            className="input"
            >
            <option value="">Choisir un tag</option>
            {availableTags.map((tag) => (
                <option key={tag} value={tag}>
                {tag}
                </option>
            ))}
            </select>

            <button
            type="button"
            onClick={handleAddDecisionsByTag}
            className="add-button"
            disabled={!selectedTag} // Désactiver le bouton si aucun tag n'est sélectionné
            >
            Ajouter décisions
            </button>

            

            <div className="form-group">
                <h3>Liste des décisions</h3>
                {formData.decisions_list.map((decision, index) => (
                <div key={index} className="decision-container">
                    <div className='decision-top-container'>
                        <h4>{decision.title}</h4> 
                        <FaTrash onClick={() => removeDecision(index)} className="delete-icon" />
                        <button type="button" onClick={() => toggleDecisionDetails(index)}>
                        {decision.isExpanded ? 'Réduire' : 'Déplier'}
                        </button>
                    </div>

                    {decision.isExpanded && (
                    <div className="decision-details">
                        <label>Forced Round :</label>
                        <div className="checkbox-container">
                            {[0, 1, 2, 3, 4].map((round) => (
                            <label key={round} className="round-checkbox">
                                <input
                                type="checkbox"
                                checked={formData.decisions_list[index].forced_rounds.includes(round)}
                                onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    let updatedForcedRounds = [...formData.decisions_list[index].forced_rounds];

                                    if (isChecked) {
                                    updatedForcedRounds.push(round);
                                    } else {
                                    updatedForcedRounds = updatedForcedRounds.filter((r) => r !== round);
                                    }

                                    handleDecisionChange(index, 'forced_rounds', updatedForcedRounds);
                                }}
                                />
                                {round+1}
                            </label>
                            ))}
                        </div>
                        <label>From Round Number :</label>
                        <input
                        type="number"
                        value={decision.from_round_number}
                        onChange={(e) => handleDecisionChange(index, 'from_round_number', Number(e.target.value))}
                        className="input"
                        onKeyDown={handleKeyDown}
                        />
                        {/* Case à cocher "Peut apparaître plusieurs fois" */}
                        <div className="form-group">
                            <label>
                            Peut apparaître plusieurs fois :
                            <input
                                type="checkbox"
                                checked={decision.decision_duplicate ?? true}
                                onChange={(e) =>
                                handleUpdateDecisionField(decision.decision_id, 'decision_duplicate', e.target.checked)
                                }
                            />
                            </label>
                        </div>

                        {/* Case à cocher "Conséquence par défaut" */}
                        <div className="form-group">
                            <label>
                            Conséquence personnalisée :
                            <input
                                type="checkbox"
                                checked={decision.custom_consequence ?? true}
                                onChange={(e) =>
                                handleUpdateDecisionField(decision.decision_id, 'custom_consequence', e.target.checked)
                                }
                            />
                            </label>
                        </div>

                        {decision.custom_consequence && (
                            <div className="decision-details">
                            <DecisionConsequences
                                decision={decision}
                                updateDecisionConsequences={handleUpdateDecisionConsequences}
                                consequences={consequences} // Transmettre les conséquences au composant enfant
                            />
                            </div>
                        )}

                    </div>
                    )}
                </div>
                ))}

                <button type="button" onClick={() => setShowDecisionDropdown(!showDecisionDropdown)} className="add-button">
                Ajouter une décision
                </button>

                {/* Intégration du nouveau composant pour gérer la liste des décisions */}
                {showDecisionDropdown && (
                  <DecisionListDropdown
                    availableDecisions={availableDecisions} // La liste des décisions disponibles
                    formData={formData} // Les données actuelles du formulaire
                    addDecision={addDecision} // Fonction pour ajouter une décision sélectionnée
                  />
                )}
            </div>
        </div>

        <div className="form-section">
            <div className="form-section-title">Autres conséquences</div>
            <OtherConsequences consequences={consequences} formData={formData} setFormData={setFormData}></OtherConsequences>

        </div>


        <div className="form-section">
            <div className="form-section-title">Introduction</div>
            <CustomIntroduction formData={formData} setFormData={setFormData}></CustomIntroduction>
        </div>

        <div className="form-section">
            <div className="form-section-title">Personnalisation des acteurs</div>
            <ActorCustomization formData={formData} setFormData={setFormData}></ActorCustomization>
        </div>

        {/* Afficher le message de succès ou d'erreur */}
        {message && <div className="message-box">{message}</div>}

        {/* Bouton "Retour aux conséquences" affiché après la soumission */}
        {message && (
          <button type="button" onClick={handleBackToConfigs} className="submit-button">
            Retour à la liste des ateliers
          </button>
        )}

        {/* Le bouton submit */}
        <button type="submit" className="submit-button" disabled={submitDisabled}>
          Envoyer la configuration
        </button>
        </form>
    </div>)}
    </Fragment>
  );
};

export default GameForm;
