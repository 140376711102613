import React, { useState, useEffect, useRef } from "react";

const OtherConsequences = ({ consequences, formData, setFormData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedConsequenceId, setSelectedConsequenceId] = useState("");
  const [selectedRound, setSelectedRound] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const searchRef = useRef(null); // Ref pour gérer le clic à l'extérieur

  // Filtrer les conséquences en fonction du terme recherché
  const filteredConsequences = consequences.filter((consequence) =>
    consequence.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectConsequence = (consequenceId, title) => {
    setSearchTerm(title); // Afficher le nom sélectionné dans la barre de recherche
    setSelectedConsequenceId(consequenceId); // Stocker l'ID de la conséquence sélectionnée
    setIsDropdownOpen(false); // Fermer le dropdown après la sélection
  };

  const handleAddOtherConsequence = () => {
    // Vérifier si une conséquence est sélectionnée et pas déjà ajoutée
    if (selectedConsequenceId && !formData.other_consequences_list.some(c => c.consequence_id === selectedConsequenceId)) {
      const newConsequence = {
        consequence_id: selectedConsequenceId,
        round_number: selectedRound,
      };
      
      setFormData({
        ...formData,
        other_consequences_list: [...formData.other_consequences_list, newConsequence],
      });
      setSearchTerm(""); // Réinitialiser la recherche après ajout
      setSelectedConsequenceId(""); // Réinitialiser la conséquence sélectionnée
    }
  };

  const handleRemoveOtherConsequence = (consequenceId) => {
    const updatedConsequences = formData.other_consequences_list.filter(
      (c) => c.consequence_id !== consequenceId
    );
    setFormData({ ...formData, other_consequences_list: updatedConsequences });
  };

  const handleRoundChange = (consequenceId, newRound) => {
    const updatedConsequences = formData.other_consequences_list.map((c) =>
      c.consequence_id === consequenceId ? { ...c, round_number: newRound } : c
    );
    setFormData({ ...formData, other_consequences_list: updatedConsequences });
  };

  // Gérer le clic à l'extérieur pour fermer la liste déroulante
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="other-consequences-section">
      <h3>Ajouter d'autres conséquences</h3>
      <div className="other-consequences-header-section">

        {/* Champ de recherche avec dropdown */}
        <div className="other-consequences-input" ref={searchRef}>
            <input
            type="text"
            value={searchTerm}
            onChange={(e) => {
                setSearchTerm(e.target.value);
                setIsDropdownOpen(true); // Ouvrir le dropdown quand l'utilisateur tape
            }}
            onClick={() => setIsDropdownOpen(true)} // Afficher toutes les options quand on clique
            placeholder="Rechercher une conséquence"
            className="other-consequences-search"
            />        
            {isDropdownOpen && (
            <ul className="other-consequences-suggestions">
                {filteredConsequences.map((consequence) => (
                <li
                    key={consequence._id}
                    onClick={() => handleSelectConsequence(consequence._id, consequence.title)}
                    className="other-consequences-item"
                >
                    {consequence.title}
                </li>
                ))}
            </ul>
            )}
        </div>

        {/* Sélection du round */}
        <select
            value={selectedRound}
            onChange={(e) => setSelectedRound(Number(e.target.value))}
            className="other-consequences-round-select"
        >
            {[1, 2, 3, 4, 5].map((round) => (
            <option key={round} value={round}>
                Tour {round}
            </option>
            ))}
        </select>

        {/* Bouton Ajouter */}
        <button 
            type="button" 
            onClick={handleAddOtherConsequence} 
            className="other-consequences-add-button"
            disabled={!selectedConsequenceId} // Désactiver si aucune conséquence n'est sélectionnée
        >
            Ajouter
        </button>
      </div>

      {/* Liste des conséquences sélectionnées */}
      <ul className="other-consequences-list">
        {formData.other_consequences_list.length > 0 ? (
          formData.other_consequences_list.map((consequence, index) => (
            <li key={index} className="other-consequences-selected-item">
              <span>{consequence.consequence_id}</span>

              <select
                value={consequence.round_number}
                onChange={(e) => handleRoundChange(consequence.consequence_id, Number(e.target.value))}
                className="other-consequences-round-select"
              >
                {[1, 2, 3, 4, 5].map((round) => (
                  <option key={round} value={round}>
                    Tour {round}
                  </option>
                ))}
              </select>

              <button
                type="button"
                onClick={() => handleRemoveOtherConsequence(consequence.consequence_id)}
                className="other-consequences-remove-button"
              >
                Supprimer
              </button>
            </li>
          ))
        ) : (
          <p>Aucune conséquence sélectionnée.</p>
        )}
      </ul>
    </div>
  );
};

export default OtherConsequences;
