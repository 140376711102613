import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../form.css'

const DecisionList = () => {
  const [decisions, setDecisions] = useState([]);
  const navigate = useNavigate();

  // Fetch les décisions via l'API
  useEffect(() => {
    const fetchDecisions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get_all_decisions`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setDecisions(data);
      } catch (error) {
        console.error('Erreur lors du chargement des décisions :', error);
      }
    };

    fetchDecisions();
  }, []);

  // Fonction pour rediriger vers l'éditeur de décision
  const handleEdit = (decisionId) => {
    navigate(`/decision_editor/${decisionId}`);
  };

  // Fonction pour rediriger vers le créateur de décision
  const handleCreateNew = () => {
    navigate("/decision_creator");
  };

  return (
    <div className="decision-list-container">
      <div className="decision-list-header">
        <button onClick={handleCreateNew} className="game-config-list-blue-button">
          Créer une nouvelle décision
        </button>
      </div>
      <ul className="decision-list">
        {decisions.map((decision) => (
          <li key={decision._id} className="decision-list-item">
            <span className="decision-list-name">{decision.title}</span>
            <button 
              onClick={() => handleEdit(decision._id)} 
              className="decision-list-orange-button">
              Éditer
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DecisionList;
