import React, { useState } from "react";

const CustomIntroduction = ({ formData, setFormData }) => {
  const [introImageUrl, setIntroImageUrl] = useState("");

  const optionsBassins = [
    { label: "Rhône-Méditerranée", value: 'rhone' },
    { label: "Adour-Garonne", value: 'adour' },
    { label: "Loire-Bretagne", value: 'loire' },
    { label: "Rhine-Meuse", value: 'rhin' },
    { label: "Seine-Normandie", value: 'seine' },
    { label: "Artois-Picardie", value: 'artois' },
  ];

  const handleAddIntroImage = () => {
    if (introImageUrl) {
      setFormData({
        ...formData,
        intro_img_list: [...formData.intro_img_list, introImageUrl],
      });
      setIntroImageUrl("");
    }
  };

  const handleRemoveIntroImage = (url) => {
    const updatedIntroImages = formData.intro_img_list.filter((img) => img !== url);
    setFormData({ ...formData, intro_img_list: updatedIntroImages });
  };

  const handleCustomIntroChange = (e) => {
    const isChecked = e.target.checked;
    setFormData((prevState) => ({
      ...prevState,
      custom_intro: isChecked,
      intro_img_list: isChecked ? prevState.intro_img_list : [], // Vider la liste si décoché
      bassin_intro: isChecked ? '' : prevState.bassin_intro, // Réinitialiser bassin si custom_intro est vrai
    }));
  };

  const handleBassinChange = (e) => {
    setFormData({ ...formData, bassin_intro: e.target.value });
  };

  return (
    <div>
      <div className="custom-intro-group">
        <label>Introduction personnalisée :</label>
        <input
          type="checkbox"
          checked={formData.custom_intro}
          onChange={handleCustomIntroChange}
        />
      </div>

      {formData.custom_intro ? (
        <>
          <div className="custom-intro-group">
            <label>Ajouter une image d'introduction :</label>
            <input
              type="text"
              value={introImageUrl}
              onChange={(e) => setIntroImageUrl(e.target.value)}
              placeholder="Ajouter une URL"
              className="custom-intro-input"
            />
            <button
              type="button"
              onClick={handleAddIntroImage}
              className="custom-intro-add-button"
            >
              Ajouter
            </button>
          </div>

          <div className="custom-intro-img-list">
            <h4>Liste d'images d'introduction :</h4>
            {formData.intro_img_list.length > 0 ? (
              formData.intro_img_list.map((url, index) => (
                <div key={index} className="custom-intro-img-item">
                  <span>Slide {index + 1}: {url}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveIntroImage(url)}
                    className="custom-intro-remove-button"
                  >
                    Supprimer
                  </button>
                </div>
              ))
            ) : (
              <p>Aucune image d'introduction ajoutée.</p>
            )}
          </div>
        </>
      ) : (
        <div className="custom-intro-group">
          <label>Sélectionner un bassin :</label>
          <select
            value={formData.bassin_intro || ""}
            onChange={handleBassinChange}
            className="input"
          >
            <option value="rhone">Rhône-Méditerranée</option>
            {optionsBassins.map((bassin) => (
              <option key={bassin.value} value={bassin.value}>
                {bassin.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default CustomIntroduction;
