import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../form.css'

const ConsequenceListComponent = () => {
  const [consequences, setConsequences] = useState([]);
  const navigate = useNavigate();

  // Fetch les conséquences via l'API
  useEffect(() => {
    const fetchConsequences = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get_all_consequences`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setConsequences(data);
      } catch (error) {
        console.error('Erreur lors du chargement des conséquences :', error);
      }
    };

    fetchConsequences();
  }, []);

  // Fonction pour rediriger vers l'éditeur de conséquence
  const handleEdit = (consequenceId) => {
    navigate(`/consequence_editor/${consequenceId}`);
  };

  // Fonction pour rediriger vers le créateur de conséquence
  const handleCreateNew = () => {
    navigate("/consequence_creator");
  };

  return (
    <div className="consequence-list-container">
      <div className="consequence-list-header">
        <button onClick={handleCreateNew} className="game-config-list-blue-button">
          Créer une nouvelle conséquence
        </button>
      </div>
      <ul className="consequence-list">
        {consequences.map((consequence) => (
          <li key={consequence._id} className="consequence-list-item">
            <span className="consequence-list-name">{consequence.title}</span>
            <button 
              onClick={() => handleEdit(consequence._id)} 
              className="consequence-list-orange-button">
              Éditer
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ConsequenceListComponent;
