import React, { useState } from 'react';

const DecisionListDropdown = ({ availableDecisions, formData, addDecision }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  // Filtrer les décisions par recherche et tag
  const filteredDecisions = availableDecisions.filter((decision) => {
    const isAlreadySelected = formData.decisions_list.some(
      (selectedDecision) => selectedDecision.decision_id === decision._id
    );
    const matchesSearch = decision.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTag = selectedTag ? decision.tags.includes(selectedTag) : true;
    return !isAlreadySelected && matchesSearch && matchesTag;
  });

  // Extraire tous les tags disponibles pour le filtre
  const allTags = [...new Set(availableDecisions.flatMap((decision) => decision.tags))];

  return (
    <div className="decision-list-dropdown">
      {/* Barre de recherche */}
      <div className="decision-list-search-bar">
        <input
          type="text"
          placeholder="Rechercher une décision..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Filtre par tags */}
      <div className="decision-list-tag-filter">
        <select value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
          <option value="">Tous les tags</option>
          {allTags.map((tag, index) => (
            <option key={index} value={tag}>
              {tag}
            </option>
          ))}
        </select>
      </div>

      {/* Liste des décisions filtrées */}
      <div className="decision-list-items">
        {filteredDecisions.map((decision) => (
          <div key={decision.id}  onClick={() => addDecision(decision)} className="decision-list-item">
            <span>
              {decision.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DecisionListDropdown;
