import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import CustomSelect from "./CustomSelect";


import useToken from "./useToken";


const GameConfigPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [numberPlayer, setNumberPlayer] = useState(6);
  const [additionalHappening, setAdditionalHappening] = useState(true);
  const [displayIndicators, setDisplayIndicators] = useState(true);
  const [workshopName, setWorkshopName] = useState("");
  const [workshopType, setWorkshopType] = useState("grand_public");
  const [workshopConfig, setWorkshopConfig] = useState({ label: "Atelier Standard", value: "6461f4ef3b2aad6690fdcd8c", custom_intro:false, bassin_intro:"rhone" });
  const [bassin, setBassin] = useState("rhone");
  const [includeClient, setIncludeClient] = useState("");
  const [workshopConfigList, setWorkshopConfigList] = useState([workshopConfig])

  const { token } = useToken();


  const options = [
    { label: "4 joueurs", value: 4 },
    { label: "5 joueurs", value: 5 },
    { label: "6 joueurs", value: 6 },
    { label: "7 joueurs", value: 7 },
    { label: "8 joueurs", value: 8 },
    { label: "9 joueurs", value: 9 },
    { label: "10 joueurs", value: 10 },
  ];

  const workshopTypes = [
    { label: "Grand public", value: "grand_public" },
    { label: "Entreprise", value: "entreprise" },
    { label: "Association", value: "association" },
    { label: "Privé", value: "prive" },
    { label: "Collectivité", value: "collectivite" },
    { label: "Enseignement supérieur", value: "enseignement_superieur" },
    { label: "Test", value: "test" },
  ];




  useEffect(() => {
    // Fonction pour récupérer les configurations depuis l'API
    const fetchWorkshopConfigs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/game_config_list`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        });
        const data = await response.json();
        console.log(data)
        const formattedConfigs = data.map((config) => ({
          label: config.name, 
          value: config._id, 
          ...config  
        }));
        console.log(formattedConfigs)

        setWorkshopConfigList(formattedConfigs);  // Met à jour l'état avec la liste formatée
      } catch (error) {
        console.error('Erreur lors du chargement des configurations:', error);
      }
    };

    // Appel de la fonction pour charger les données à chaque rendu
    fetchWorkshopConfigs();
  }, []);

  const navigate = useNavigate();

  const handleTitleChange = (event) => {
    setWorkshopName(event.target.value);
  };

  const handleActorChange = (selectedOption) => {
    setNumberPlayer(selectedOption.value);
  };

  const handleWorkshopTypeChange = (selectedOption) => {
    setWorkshopType(selectedOption.value);
  };

  const handleWorkshopConfigChange = (selectedOption) => {
    setWorkshopConfig(selectedOption);
  }

  const handleAdditionalHappeningChange = () => {
    setAdditionalHappening(!additionalHappening);
  };

  const handleDisplayIndicatorsChange = () => {
    setDisplayIndicators(!displayIndicators);
  };




  const handleSubmit = async (startNow) => {
    setLoading(true);
    const dataReceive = {
      number_player: numberPlayer,
      show_happening: additionalHappening,
      show_additional_score: displayIndicators,
      workshop_name: workshopName,
      status: "not started",
      type: workshopType,
      include_client: includeClient, 
      bassin: bassin,
      game_config: workshopConfig._id
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/newgame`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.token,
        },
        body: JSON.stringify(dataReceive),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("response", responseData);
      const gameId = responseData.game_id;

      // Redirigez vers la page du jeu avec le gameId
      if (workshopConfig.custom_intro) {
        navigate("/custom_intro/" + gameId + "?game_config="+workshopConfig._id);
      } else if (workshopConfig.bassin_intro){
        navigate("/intro/" + gameId + "?bassin="+workshopConfig.bassin_intro);
      } else {
        navigate("/intro/" + gameId + "?bassin=rhone");
      }
    } catch (error) {
      // Gérez les erreurs ici
      setLoading(false);
      console.error("Error posting data:", error);
    }
  };

  return (
    <Fragment>
      {loading ? (
        <div className="loader-container">
          <img src="./loader.gif" className="loader" />
        </div>
      ) : (
        <div className="game-config">
          <div className="header_selection_container">
            <h1 className="home-title">Configuration du jeu</h1>
            <div className="form-group">
              <div className="num_actor_text">
                Vous avez selectionné {numberPlayer} acteurs.
              </div>
            </div>
          </div>
          <div className="form-container">
            <div className="additional_game_selection_container">
              <div className="input-label">Nom de votre atelier : </div>
              <input
                placeholder="Atelier de démonstration"
                value={workshopName}
                onChange={handleTitleChange}
                className="input"
              />
            </div>

            <div className="additional_game_selection_container">
              <div className="input-label">Nombre d'acteurs :</div>
              <CustomSelect
                options={options}
                onSelect={handleActorChange}
                defaultValue={{ label: "6 joueurs", value: 6 }}
              />
            </div>
            {numberPlayer !== 4 && (
              <div className="additional_game_selection_container">
                <div className="input-label">Dont le client</div>
                <CustomSelect
                  options={[
                    { label: "Oui", value: true },
                    { label: "Non", value: false },
                  ]}
                  onSelect={(selectedOption) => setIncludeClient(selectedOption.value)}
                  defaultValue={{ label: "Non", value: false }}
                />
              </div>
            )}

            <div className="additional_game_selection_container">
              <div className="input-label">Configuration Atelier : </div>
              <CustomSelect
                options={workshopConfigList}
                onSelect={handleWorkshopConfigChange}
                // defaultValue={{ label: "Atelier Standard", value: "6461f4ef3b2aad6690fdcd8c", }}
              />
            </div>


            <div className="additional_game_selection_container">
              <div className="input-label">Type de public : </div>
              <CustomSelect
                options={workshopTypes}
                onSelect={handleWorkshopTypeChange}
                defaultValue={{ label: "Grand public", value: "grand_public" }}
              />
            </div>

            <div className="additional_game_selection_container">
              <div className="form-group">
                <div className="selector_container">
                  <div className="name">Afficher les scores : </div>
                  <label className="switch-button">
                    <input
                      type="checkbox"
                      checked={displayIndicators}
                      onChange={handleDisplayIndicatorsChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="selector_container">
                  <div className="name">Happening supplémentaire :</div>
                  <label className="switch-button">
                    <input
                      type="checkbox"
                      checked={additionalHappening}
                      onChange={handleAdditionalHappeningChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

              <div className="form-group center_element">
                <button
                  className="blue-button"
                  onClick={() => handleSubmit(true)}
                >
                  Créer le jeu et jouer maintenant
                </button>
                {/* <button
                  className="blue-button"
                  onClick={() => handleSubmit(false)}
                >
                  Créer le jeu pour plus tard
                </button> */}
              </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default GameConfigPage;
