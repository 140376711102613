import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../form.css";

const GameConfigList = () => {
  const [gameConfigs, setGameConfigs] = useState([]);
  const navigate = useNavigate();

  // Fetch les configurations via l'API
  useEffect(() => {
    const fetchGameConfigs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/game_config_list_complete`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setGameConfigs(data);
      } catch (error) {
        console.error('Erreur lors du chargement des configurations :', error);
      }
    };

    fetchGameConfigs();
  }, []);


  const handleDownload = async (gameConfigId) => {
    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/get_pdf/${gameConfigId}`);

      if (response.ok) {
        // La réponse est OK, alors traitons la réponse comme nécessaire
        // Dans cet exemple, nous allons simplement rediriger l'utilisateur vers le rapport téléchargé
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `game_notebook_${gameConfigId}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } else {
        console.error('Erreur lors du téléchargement du rapport.');

      }
    } catch (error) {
      console.error('Erreur lors du téléchargement du rapport :', error);

    }
  };

  // Fonction pour rediriger vers l'éditeur
  const handleEdit = (configId) => {
    navigate(`/game_config_editor/${configId}`);
  };

  // Fonction pour rediriger vers le créateur de config
  const handleCreateNew = () => {
    navigate("/game_config_creator");
  };

  return (
    <div className="game-config-list-container">
      <div className="game-config-list-header">
        <button onClick={handleCreateNew} className="game-config-list-blue-button">
          Créer une nouvelle configuration
        </button>
      </div>
      <ul className="game-config-list">
        {gameConfigs.map((config) => (
          <li key={config._id} className="game-config-list-item">
            <span className="game-config-list-name">{config.name}</span>
            <div className='game-config-list-button-container'>
                <button 
                onClick={() => handleDownload(config._id)} 
                className="game-config-list-orange-button"
                style={{"margin-right":'5px'}}>
                Télécharger carnet
                </button>
                <button 
                onClick={() => handleEdit(config._id)} 
                className="game-config-list-orange-button">
                Éditer
                </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GameConfigList;
