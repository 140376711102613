import React, { useState, useEffect } from 'react';

const DecisionConsequences = ({ decision, updateDecisionConsequences, consequences }) => {
  const [isConsequencesOpen, setIsConsequencesOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  
  // Filtrer les conséquences par recherche et tag
  const filteredConsequences = consequences.filter((consequence) => {
    const matchesSearch = consequence.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTag = selectedTag ? consequence.tags.includes(selectedTag) : true;
    return matchesSearch && matchesTag;
  });

  const handleAddConsequenceTaken = (consequenceId) => {
    updateDecisionConsequences(decision.decision_id, 'consequences_taken', consequenceId);
  };

  const handleAddConsequenceNotTaken = (consequenceId) => {
    updateDecisionConsequences(decision.decision_id, 'consequences_not_taken', consequenceId);
  };

  const handleRemoveConsequenceTaken = (consequenceId) => {
    updateDecisionConsequences(decision.decision_id, 'remove_taken', consequenceId);
  };

  const handleRemoveConsequenceNotTaken = (consequenceId) => {
    updateDecisionConsequences(decision.decision_id, 'remove_not_taken', consequenceId);
  };

  // Extraire tous les tags disponibles pour le filtre
  const allTags = [...new Set(consequences.flatMap(consequence => consequence.tags))];

  return (
    <div className="consequence-section-decision">
      <div className="form-section-title">Conséquences liées à la mesure</div>
      <div className="form-group">
        <button
          type="button"
          className="add-button"
          onClick={() => setIsConsequencesOpen(!isConsequencesOpen)}
        >
          Ajouter une conséquence
        </button>

        {/* Afficher la barre de recherche et le filtre par tags si le menu est déplié */}
        {isConsequencesOpen && (
          <>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Rechercher une conséquence..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="tag-filter">
              <select value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
                <option value="">Tous les tags</option>
                {allTags.map((tag, index) => (
                  <option key={index} value={tag}>
                    {tag}
                  </option>
                ))}
              </select>
            </div>

            <div className="consequences-list">
              {filteredConsequences.map((consequence) => (
                <div key={consequence._id} className="consequence-item">
                  <div className="consequence-title">{consequence.title}</div>
                  <div className="button-consequence-section">
                    <button
                      type="button"
                      onClick={() => handleAddConsequenceTaken(consequence._id)}
                      className="select-consequence-button"
                    >
                      Ajouter si mesure prise
                    </button>
                    <button
                      type="button"
                      onClick={() => handleAddConsequenceNotTaken(consequence._id)}
                      className="select-consequence-button"
                    >
                      Ajouter si mesure non prise
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Conséquences sélectionnées */}
      <div className="form-group">
        <label className="input-label">Conséquences si mesure prise :</label>
        <ul className="selected-consequences-list">
          {decision.custom_consequences_taken.length > 0 ? (
            decision.custom_consequences_taken.map((consequenceId, index) => (
              <li key={index} className="selected-consequence-item">
                {consequenceId}
                <button
                  type="button"
                  onClick={() => handleRemoveConsequenceTaken(consequenceId)}
                  className="remove-consequence-button"
                >
                  Supprimer
                </button>
              </li>
            ))
          ) : (
            <p>Aucune conséquence ajoutée pour une mesure prise.</p>
          )}
        </ul>
      </div>

      <div className="form-group">
        <label className="input-label">Conséquences si mesure non prise :</label>
        <ul className="selected-consequences-list">
          {decision.custom_consequences_not_taken.length > 0 ? (
            decision.custom_consequences_not_taken.map((consequenceId, index) => (
              <li key={index} className="selected-consequence-item">
                {consequenceId}
                <button
                  type="button"
                  onClick={() => handleRemoveConsequenceNotTaken(consequenceId)}
                  className="remove-consequence-button"
                >
                  Supprimer
                </button>
              </li>
            ))
          ) : (
            <p>Aucune conséquence ajoutée pour une mesure non prise.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default DecisionConsequences;
