import React, { useState } from "react";

const TagSelector = ({ formData, setFormData, existingTags }) => {
  const [newTag, setNewTag] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Empêche la soumission du formulaire
    }
  };

  const handleAddTag = (tag) => {
    if (tag && !formData.tags.includes(tag)) {
      setFormData({
        ...formData,
        tags: [...formData.tags, tag],
      });
      setNewTag(""); // Réinitialiser l'input après ajout
      setFilteredTags([]); // Réinitialiser les suggestions après ajout
    }
  };

  const handleRemoveTag = (tag) => {
    setFormData({
      ...formData,
      tags: formData.tags.filter((t) => t !== tag),
    });
  };

  const handleTagInputChange = (e) => {
    const inputValue = e.target.value;
    setNewTag(inputValue);

    if (inputValue) {
      const matchingTags = existingTags.filter((tag) =>
        tag.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredTags(matchingTags);
    } else {
      setFilteredTags(existingTags); // Montrer tous les tags existants si l'input est vide
    }
  };

  const handleInputFocus = () => {
    setFilteredTags(existingTags); // Afficher les suggestions dès qu'on clique dans le champ
  };

  return (
    <div className="tag-selector">
      <div className="tag-input-group">
        <input
          type="text"
          value={newTag}
          onChange={handleTagInputChange}
          onFocus={handleInputFocus}
          placeholder="Ajouter un tag"
          className="tag-input"
          onKeyDown={handleKeyDown}
        />
        <button
          type="button"
          onClick={() => handleAddTag(newTag)}
          className="tag-add-button"
        >
          Ajouter
        </button>

        {/* Suggestions de tags */}
        {filteredTags.length > 0 && (
          <div className="tag-suggestions">
            {filteredTags.map((tag, index) => (
              <span
                key={index}
                className="tag-suggestion-item"
                onClick={() => handleAddTag(tag)}
              >
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className="tag-list">
        {formData.tags.map((tag, index) => (
          <span key={index} className="tag-item">
            {tag}
            <button
              type="button"
              onClick={() => handleRemoveTag(tag)}
              className="tag-remove-button"
            >
              &times;
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagSelector;
